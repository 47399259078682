<template>
  <v-dialog
    v-model="show"
    light
    :width="$vuetify.breakpoint.mobile ? '' : '30%'"
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
    >
    <v-card flat>

      <v-card-title class="azul2 turquesa--text">
        {{ options.title }} <v-spacer />
        <v-btn :ripple="false" text plain @click="closeDialog">
          <img src="assets/ic-close-celest.svg" width="16px"/>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-container class="pt-12 text-left">
          <v-row v-if="options.withSoon">
            <v-col>
              <span class="chivo font-weight-bold">Esta etapa comenzará pronto.<br>Anotate a nuestras News y entérate al instante.</span>
            </v-col>
          </v-row>
          <v-form ref="newsForm" v-model="validForm" lazy-validation>
            <v-row>
              <v-col class="chivo text-left">
                Ingrese su correo electrónico
                <v-text-field :rules="emailRules" class="bold-placeholder" v-model="email" placeholder="news@tokenfit.io" required />
              </v-col>
            </v-row>
            <v-row class="text-center">
              <v-col>
                <vue-recaptcha @verify="validateReCaptcha" class="d-flex justify-center" :sitekey="reCaptchaKey" />
              </v-col>
            </v-row>
            <v-row class="text-center">
              <v-col>
                <enviar-button @click="enviarMailAndClose" :enabled="validForm && email.length>0 && captchaVerify"/>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import * as emailService from '../../services/emailService';
  import EventBus from '@/utils/eventbus'
  import VueRecaptcha from 'vue-recaptcha';
  import { emailRules } from '@/validateRules'
  const reCaptchaKey = process.env.VUE_APP_RECAPTCHA_KEY;
  export default {
    name: 'NewsDialog',
    components: {
      EnviarButton: () => import('@/views/components/EnviarButton'),
      VueRecaptcha
    },
    data() {
      return {
        show: false,
        email: '',
        options: {
          title: 'INSCRIBITE',
          withSoon: false,
        },
        defaultOptions: {
          title: 'INSCRIBITE',
          withSoon: false
        },
        reCaptchaKey,
        validForm: false,
        emailRules,
        captchaVerify: false
      }
    },
    created() {
      EventBus.$on('show-news-dialog', this.showDialog);
      EventBus.$on('hide-news-dialog', this.closeDialog);
    },
    methods: {
      showDialog(options) {
        // reset options 
        for(let key in this.defaultOptions) {
          this.options[key] = this.defaultOptions[key]
        }

        // verify if some options were passed as argument
        if(options) {
         if (options.title) {
            this.options.title = options.title
          }
          if (options.withSoon) {
            this.options.withSoon = options.withSoon
          }
        } 
        this.email = ''
        this.show = true
        EventBus.$emit('show-dialog')
      },
      closeDialog() {
        this.email = '',
        this.show = false
        EventBus.$emit('hide-dialog')
      },
      enviarMailAndClose() {

        emailService.sendNews({email: this.email})
          .then(resp => {
            if (resp.data.status == 'OK') {
              EventBus.$emit('show-snackbar', {msg: 'ENVIADO CORRECTAMENTE', color: 'success'})
            } else {
              EventBus.$emit('show-snackbar', {msg: `OCURRIO UN ERROR`, color: 'red accent-2'})
            }
          })
          .catch(err => {
            EventBus.$emit('show-snackbar', {msg: `OCURRIO UN ERROR: ${err.message}`, color: 'red accent-2'})
          })
          .finally(() => this.closeDialog())

        EventBus.$emit('show-snackbar', {msg: 'ENVIANDO, AGUARDE POR FAVOR...', color: 'teal'})
      },
      validateReCaptcha() {
        this.captchaVerify = true
      }
    }
  }
</script>

<style>

</style>